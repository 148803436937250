import React from 'react';
import styled from 'styled-components';
import ProductGrid from './ProductGrid';
import ProductListPage from 'rev.sdk.js/Templates/ProductList';

function ProductList(props) {
  return (
    <Wrapper>
      <ProductListPage {...props} ProductGrid={ProductGrid} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  #rev-ProductListPage .ant-pagination {
    display: none;
  }
`;

export default ProductList;
