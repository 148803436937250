import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';

export function ProductItem(props) {
  const {product, onClick, mobile, empty} = props;
  return (
    <ProductWrapper mobile={mobile} onClick={onClick} empty={empty}>
      {!empty && (
        <>
          <div className="img-wrapper">
            <img
              src={(() => {
                if (product.images && product.images.length > 0) {
                  if (product.images[0] === '') {
                    return '../../images/empty-img.png';
                  }
                  if (product.images[0].indexOf('base64') < 0) {
                    return '../../images/loading.png';
                  } else {
                    return product.images[0];
                  }
                } else {
                  return '../../images/empty-img.png';
                }
              })()}
              alt="product"
            />
          </div>
          <div className="info" style={{marginTop: 15}}>
            <h3>{product.name}</h3>
            <p
              style={{
                letterSpacing: 1.5,
                fontWeight: 'bold',
                color: '#d8d8d8',
              }}>
              {product.model}
            </p>
            {product.price !== undefined && <p>${product.price}</p>}
          </div>
        </>
      )}
    </ProductWrapper>
  );
}

export function ArticleItem(props) {
  const {product, onClick, mobile} = props;
  return (
    <ArticleWrapper mobile={mobile} onClick={onClick}>
      <img src={product.image || '../../images/empty-img.png'} alt="article" />

      <div className="info">
        <h3 className="title">{product.title || '無標題'}</h3>
        <p className="date">
          {new Date(product.created).toLocaleString() || ''}
        </p>
        <p className="outline">{product.outline || ''}</p>
      </div>
    </ArticleWrapper>
  );
}

export function VideoItem(props) {
  const {video, onClick, mobile, empty} = props;
  const [vimeoData, setVimeoData] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      //286898202
      // let vimeoResp = await fetch(`http://vimeo.com/api/v2/video/${video.vid}.json`)
      let encodeLink = encodeURIComponent(`https://vimeo.com/${video.vid}`);
      let vimeoResp = await fetch(
        `https://vimeo.com/api/oembed.json?url=${encodeLink}`,
      );
      let data = await vimeoResp.json();
      setVimeoData(data);
    }
    if (video) {
      AppActions.setLoading(true);
      fetchData();
      AppActions.setLoading(false);
    }
  }, [video]);

  return (
    <ProductWrapper
      mobile={mobile}
      onClick={() => {
        onClick(video);
      }}
      empty={empty}>
      {!empty && (
        <>
          <div className="img-wrapper">
            <img
              src={(() => {
                if (vimeoData) {
                  return `${vimeoData.thumbnail_url}`;
                } else {
                  return `../../images/empty-img.png`;
                }

                // return `https://img.youtube.com/vi/${video.vid}/default.jpg`;
              })()}
              alt="product"
            />
          </div>
          <div className="info" style={{marginTop: 15}}>
            <h3 className="title">{video.title}</h3>
            <p className="outline">
              {(video.description && video.description) || ''}
            </p>
          </div>
        </>
      )}
    </ProductWrapper>
  );
}

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  max-width: ${(props) => (props.mobile ? '300px' : 'none')};
  margin-top: 30px;
  cursor: pointer;

  & > .info {
    padding: 20px;
    flex: 1;
    & > .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & > .outline {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & > .date {
      color: var(--primaryColor);
      font-size: 13px;
      letter-spacing: 2px;
    }
  }

  & > img {
    flex-basis: ${(props) => (props.mobile ? '200px' : '400px')};
    height: ${(props) => (props.mobile ? '150px' : '250px')};
    max-width: 400px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${(props) => (props.mobile ? '10px' : '20px')};
  }
`;

const ProductWrapper = styled.div`
  margin: 10px;
  width: ${(props) => (props.mobile ? '140px' : '260px')};
  height: ${(props) =>
    props.empty ? '0px' : props.mobile ? '180px' : '420px'};
  /* overflow: hidden; */

  & > .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 10px;
    /* background-color: white; */

    & > h3 {
      flex: 1;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      /* overflow: hidden; */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
      overflow: hidden;
    }

    & > p {
      margin-top: 10px;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
    }
    & > .outline {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  & > .img-wrapper {
    position: relative;
    height: 75%;
    width: 100%;
    cursor: pointer;
    transition: 300ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);

    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.2);
    }

    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: 200ms;
      /* &:hover {
        transform: scale(1.1);
      } */
    }
  }
`;
